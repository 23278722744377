@keyframes wobble {
	from {
		transform: translate3d(0, 0, 0);
	}

	15% {
		transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
	}

	30% {
		transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
	}

	45% {
		transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
	}

	60% {
		transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
	}

	75% {
		transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

.login-form {
	align-self: center;
	margin: 0 auto;
	box-shadow: 0 0 15px rgba(0,0,0,0.4);
	transition: all 0.4s ease-in-out;
	border-radius: 12px;
	background: rgba(255,255,255,0.5);
	backdrop-filter: blur(8px);
	padding: 2rem;
	z-index: 10;

	img {
		max-height: 40px;
	}

	header {
		margin-bottom: 2rem;
	}

	&:hover {
		transform: scale(1.02);
		box-shadow: 0 0 25px rgba(0,0,0,0.3);
	}

	&.wrong {
		animation: 0.8s wobble ease-in-out;
	}

	.second-column {
		border-color: #bbb !important;
	}

	.content {
		display: flex;

		> * {
			flex-grow: 1;
		}

		> *:not(:last-child) {
			margin-right: 1rem;
		}

		> *:not(:first-child) {
			border-left: solid 1px #bbb;
			padding-left: 1rem;
		}
	}

	@include mobile {
		min-width: 0 !important;
		max-width: 95%;
	
		.content {
			display: block;

			> * {
				padding: 0 !important;
				margin: 0 !important;
				border: none !important;
			}

			> *:not(:first-child) {
				margin-top: 2rem !important;
			}
		}

		.invisible {
			display: none;
		}
	}
}

button, input {
	&.full {
		display: block;
		width: 100%;
	}
}

button, button:focus {
	outline: 0 !important;
	box-shadow: none !important;
}

input.inline {
	display: inline-block;
}

.color-input {
	input:not(.value) {
		display: none !important;
	}
}

.custom-switch {
	*:hover {
		cursor: pointer;
	}
}

.toggle {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
	vertical-align: middle;
	margin: 0;
  
	input {
		opacity: 0;
		width: 0;
		height: 0;

		&:checked {
	 		& + .slider {
				background-color: $primary;
			}
		}

		&:checked + .slider::before {
			-webkit-transform: translateX(26px);
			-ms-transform: translateX(26px);
			transform: translateX(26px);
		}
	}
  
	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: .4s;
		transition: .4s;
	
		&::before {
			position: absolute;
			content: "";
			height: 26px;
			width: 26px;
			left: 4px;
			bottom: 4px;
			background-color: white;
			-webkit-transition: .4s;
			transition: .4s;
		}

		&.round {
			border-radius: 34px;

			&::before {
				border-radius: 50%;
			}
		}
	}

	& + label {
		vertical-align: middle;
	}
}


.form-control {
	&.inline {
		display: inline;
		vertical-align: middle;
		margin-right: 0.5rem;
		font-size: inherit;
		padding: 0 0.5rem;
		margin-bottom: 0.5rem;
		border-radius: 6px;
		height: auto !important;
	}
}