.theme-dark {

	a:not(.btn) {
		color: lighten($primary, 20);

		&:hover {
			color: lighten($primary, 5)
		}
	}

	main, .content > div, .react-datepicker__header {
		background: #222;
		color: #aaa;
	}

	.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header,
	.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
		color: #aaa;
	}
	.react-datepicker__day--today {
		color: white;
	}
	.react-datepicker, .react-datepicker__header {
		border-color: #666;
	}
	.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
	.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
		border-bottom-color: #222;
	}

	.bg-white, input, select, textarea, .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link, .modal-content, .form-control,
	.react-datepicker, .dropdown-menu {
		background: #444 !important;
		color: #ccc !important;
	}
	.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
		border-bottom-color: #666;
	}

	.dropdown-item:hover, .dropdown-item:focus {
		background: rgba(255,255,255,0.1);
	}

	.form-control:focus {
		color: white !important;
	}

	.modal-header, .modal-footer {
		border-color: #666;
	}

	input, select, textarea {
		border: solid 1px #666;
	}

	.input-group-text {
		border-color: #666;
		background: #666;
		color: #ccc;
	}

	.custom-file-label {
		background: #444;
		color: #ccc;
		border: solid 1px #666;

		&:after {
			background: lighten(#444, $amount: 10%);
			color: lighten(#ccc, 10%)
		}
	}

	nav.sidebar li, nav.sidebar a {
		border-color: darken($primary, 8);
	}

	nav.sidebar li, nav.sidebar a, .table .btn {
		color: #ccc !important;
	}

	.btn {
		&.bg-transparent {
			color: #aaa;
		}
	}

	.nav-tabs .nav-link.active, .nav-link:hover, .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
		border-top-color: #777 !important;
		border-left-color: #777 !important;
		border-right-color: #777 !important;

		&:hover {
			border-bottom-color: #777 !important;
		}
	}
	.nav-tabs {
		border-color: #777;
	}
	.nav-item a.active {
		border-bottom-color: #444 !important;
	}
	table th {
		border-color: #aaa !important;
	}

	.table {
		color: #ccc;
	}

	table.sortable tbody tr:hover {
		background: rgba(255,255,255,0.1) !important;
	}

	.bg-primary {
		background: darken($primary, 20) !important;
	}

	.text-white {
		color: #ccc !important;
	}

	.alert-primary {
		background: lighten($primary, 20);
		border-color: lighten($primary, 10);
	}
	.alert-danger {
		background: lighten($danger, 20);
		border-color: lighten($danger, 10);
	}
	.alert-warning {
		background: lighten($warning, 20);
		border-color: lighten($warning, 10);
	}
	.alert-secondary {
		background-color: #333;
		color: #ccc;
		border-color: #222;
	}

	aside.editor {
		background: #888;
		color: #eee;

		label {
			color: #ddd;
		}
	}

	.helpscreen {
		&, .open {
			background: #333 !important;
			border-color: lighten($primary, 20);
		}

		.open {
			border-top-color: #333;

			&, .text-primary {
				color: lighten($primary, 20) !important;
			}
		}
	}

	.offline {
		.content {
			background: #222;
			color: #ccc;
		}
	}

	hr {
		border-color: rgba(255,255,255,0.1);
	}

	.modal-header button.close {
		color: #aaa;
		text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
	}

	.page-link {
		background: transparent !important;
		color: lighten($primary, 20);

		&:hover {
			color: lighten($primary, 10);
		}
	
		&, & .page-item.disabled {
			border-color: #666 !important;
		}
	}

	.login-form {
		background: rgba(0,0,0,0.6);
		color: #ddd;
		backdrop-filter: blur(10px);

		i {
			color: #888;
		}
	
		.content > * {
			border-color: #777 !important;
		}
	}

	.wrapper.login {
		background: url("../../../public/background_login_dark.png") no-repeat center center;
		background-size: cover;
	}

	.toggle {
		input:checked + .slider {
			background: darken($primary, 10%);
		}

		.slider {
			background: #777;
		}

		.slider::before {
			background: #444;
		}
	}

	.datepicker {
		.selector {
			background: #333;
			
			.header {
				.header-year {
					color: #777;
				}
			}

			&::before {
				border-bottom-color: #333;
			}
		}
	}

	.command-palette {
		.matches {
			background: #333 !important;
			color: #777;
		
			label {
				border-color: #444 !important;
			}
		}
	}
}

