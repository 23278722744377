$flag : 8px;

.datepicker {
	position: relative;
	z-index: 999;

	.selector {
		position: absolute;	
		background: white;
		padding: 0.5rem;
		margin-top: calc($flag + 1px);
		display: none;
		grid-template-columns: repeat(7, 1fr);
		align-items: center;
		justify-content: center;
		font-size: 1rem;
		min-width: 300px;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);

		.header {
			grid-column: 1 / span 7;
			text-align: center;
			font-size: 1.4rem;
			padding: 0.5rem 0;
			margin-bottom: 1rem;
			display: grid;
			grid-template-columns: 30px auto 30px;
			cursor: pointer;

			.header-year {
				color: #555;
			}

			&.months, &.years {
				grid-column: 1 / span 3;
			}

			.navigation {
				cursor: pointer;
			}
		}

		.day, .weekday, .month, .year {
			text-align: center;
			padding: 0.5rem;
		}

		.day, .month, .year {
			cursor: pointer;

			&.selected {
				background: $primary !important;
				color: white;
			}

			&.today {
				background:rgba(0, 0, 0, 0.4);
			}

			&:not(.today):hover {
				background: rgba(0,0,0,0.1);
			}
		}

		.weekday {
			color: $primary;
			text-transform: uppercase;
			font-size: 1.1rem;
		}

		.month, .year {
			font-size: 1.2rem;
			padding: 1rem;
			cursor: pointer;
		}

		&::before {
			position: absolute;
			content: " ";
			top: calc($flag * -1);
			left: 4rem;
			display: block;
			width: 0; 
			height: 0; 
			border-left: $flag solid transparent;
			border-right: $flag solid transparent;

			border-bottom: $flag solid white;
		}
	}

	.clear {
		position: absolute;
		top: 50%;
		cursor: pointer;
		right: 0.5rem;
		transform: translateY(-50%);
	}
}