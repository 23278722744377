.command-palette {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 3000;
	background: rgba(0,0,0,0.2);
	backdrop-filter: blur(4px);

	.palette {
		width: 30vw;
		max-width: 600px;
		box-shadow: 0 0 12px rgba(0,0,0,0.4);

		input {
			width: 100%;
			font-size: 1.5rem;
			padding: 0.5rem 1rem;
			position: relative;
			z-index: 3001;
		}

		.matches {
			background: white;
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;

			label {
				display: block;
				padding: 0.5rem;
				font-size: 1rem;
				margin: 0;
				background: transparent;
				
				&:not(:last-of-type) {
					border-bottom: solid 1px #eee;
				}
			
				&.active, &:hover {
					background: rgba(0,0,0,0.1);
				}

				&:last-of-type {
					border-bottom-left-radius: 8px;
					border-bottom-right-radius: 8px;
				}
			
				.badge {
					float: right;
				}
			}
		}
	}
}