@mixin shieldColor($name, $color) {
	&.#{$name} {
		border-color: $color;

		& > *:first-child {
			background: $color;
		}
	}
}

.shield {
	border: solid 1px $secondary;
	font-size: 0.8rem;
	display: inline-block;
	border-radius: 4px;
	margin: 0 1rem 1rem 0;
	white-space: nowrap;
	display: inline-flex;
	max-width: 100%;

	& > * {
		padding: 0.1rem 0.5rem;
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	& > *:first-child {
		background: $secondary;
		color: white;
		text-transform: uppercase;
	}

	@include shieldColor(primary, $primary);
	@include shieldColor(warning, $warning);
	@include shieldColor(info, $info);
	@include shieldColor(success, $success);
	@include shieldColor(danger, $danger);

	&.warning {
		& > *:first-child {
			color: $secondary;
		}
	}
}