.room-manager {
    .input-group-text{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    
    .bs-popover-right{
        z-index:3000;
    }

    .rbc-calendar{
        .rbc-toolbar {
            .rbc-btn-group {
                button{
                    color: #ccc;
                    &:focus {
                        color: black;
                    }
                }
                .rbc-active {
                    color: black;
                }
            }
        }
    }
    
    .modal-body{

        .collisionWarning{
            color: #dc3545;
        }

        .react-datepicker-popper{
            z-index: 4;
        }

        .react-datepicker__time-list-item{
            color: black;
        }

        .react-datepicker__triangle {
            display: none;
        }

        .rbc-toolbar {
            flex-wrap: nowrap;
        }
        
        .rbc-overlay {
            z-index: 2000;
            .rbc-event.rbc-event-continues-after,.rbc-event.rbc-selected.rbc-event-continues-after{
                width: fit-content;
                background-color: transparent;
                &:focus { 
                    outline: none;
                }
                color: #dc3545;
            }
        }
        
        .rbc-today {
            background-color: transparent;
        }
        
        .rbc-month-view {
            z-index: 1;
        }
        
        .rbc-now {
            .rbc-button-link {
                color: #dc3545
            }
        }
        
        .rbc-month-view {
            .rbc-event, .rbc-event.rbc-selected {
                width: fit-content;
                background-color: #3174ad;
                &:focus { 
                    outline: none;
                }
            }
        }
    }
}

.nomargin {
	svg {
		margin: 0 !important;
	}
}