@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.phonebook-context {
	.avatar {
		width: 150px;
		height: 150px;
		border-radius: 50%;
		overflow: hidden;
		margin: 0 auto;
		position: relative;

		img {
			width: 150px;
			height: 150px;
			object-fit: cover;
		}

		&__input {
			width: 0.1px;
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: -1;

		}

		.no-avatar {
			color: $primary;
			border: solid 2px $primary;
			width: 98%;
			height: 98%;
			border-radius: 50%;
			aspect-ratio: 1;
			display: grid;
			align-items: center;
			justify-items: center;
			user-select: none;

			> span {
				font-size: 4rem;
				font-weight: 200;
			}
		}

		.avatar-edit {
			opacity: 0;
			position: absolute;
			bottom: 0;
			left: 0;
			background: linear-gradient(to bottom, transparent, rgba(0,0,0,0.8));
			width: 100%;
			height: 50%;
			padding-top: 2rem;
			cursor: pointer;
			text-align: center;
			transition: all 0.3s ease-in-out;

			&:hover {
				opacity: 0.9;
			}
		}
	}

	.row.av {
		.delete {
			opacity: 0;
			position: absolute;
			top: 0;
			right: 2rem;
			transition: all 0.3s ease-in-out;
		}

		&:hover {
			.delete {
				opacity: 1;
			}
		}
	}

	.currentAvatar {
		width: 150px;
		height: 150px;
		overflow: hidden;
		display: flex;
		justify-content: flex-end;

		.currentAvatar__close {
			font-size: 2rem;
			position: absolute;
			margin-top: -1rem;
			cursor: pointer;
		}

		.currentAvatar__image {
			width: 150px;
			height: 150px;
			border-radius: 50%;
			object-fit: cover;
		}
	}

	.contact-edit {
		.btn.delete {
			border-radius: 5000px;
			color: $danger;
			aspect-ratio: 1;
			padding: 0;
			position: absolute;
			top: 0;
			right: 0;
			transition: 0.2s ease-in-out;

			&:hover {
				background: $danger;
				color: white;
			}

			svg {
				font-size: 2rem;
				padding: 0;
				margin: 0;
			}
		}
	}

	.dropdown-menu {
		background: $warning !important;
		.dropdown-item {
			cursor: pointer;
		}
		overflow-y: scroll;
		height: 60vh;
	}


	.scale {
		transition: transform 0.2s ease-in-out;

		&:hover {
			transform: scale(1.2);
		}
	}

	.confirm {
		transition: all 0.2s ease-in-out;

		&:not(:last-of-type) {
			margin-right: 0.2rem;
		}
		
		&:last-of-type {
			margin-right: 0.5rem;
		}

		&:hover {
			transform: scale(1.2);
		}

		svg {
			margin: 0;
			font-size: 1.5rem;
		}
	}

	.badge-lang-delete {
		cursor: pointer;
		transition: all 0.2s ease-in-out;

		&:hover {
			background: $danger;
			color: rgba(255,255,255,0.4);
			position: relative;

			&::before {
				content: "X";
				color: white;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	table.sortable {
		img {
			max-width: 40px;
			height: auto;
			aspect-ratio: 1;
		}
	}
}

.split-menu {
	display: grid;
	padding: 0;
	grid-gap: 0;
	grid-template-columns: 1fr 3fr;

	span {
		display: block;
		color: $dark;
		padding: 0.5rem 1rem;
		cursor: pointer;

		&:hover, &.active {
			background: rgba(255,255,255,0.3);
		}
	}

	.level2 {
		background: rgba(255,255,255,0.2);
	}
}

.value-card {
	background: rgba(0,0,0,0.1);
	border-radius: 6px;
	padding: 1rem;

	.btn {
		padding: 0;
	}

	svg {
		font-size: 1rem;
	}
}