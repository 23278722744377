@import "../../assets/css/variables";
@import "../../assets/css/mixins";

.hours-edit {

	label {
		text-align: right;
	}

	select, textarea {
		grid-column: 2 / span 2;

		&.thin {
			grid-column: span 1;
		}
	}

	.three-thirds {
		@include smallScreen {
			grid-template-columns: 1fr 2fr 2fr;
		}
	}

	.react-datepicker-wrapper {
		display: block;
		grid-column: 2 / span 2;
	}

	.react-datepicker__day--selected {
		background: $primary;
	}
}

.btn.del {
	position: absolute;
	right: 0;
	top: 0;
}

.date-input {
	position: relative;
}

.deployment-info {
	background: #f5f5f5;

	& > b {
		text-align: right;
		color: #aaa;
		font-size: .8rem;
		font-weight: normal;
	}

	h2 {
		border-bottom: solid 1px #ccc !important;
		padding-bottom: 1rem;
		font-size: 2.5rem;
		font-weight: 100;
	}
}
